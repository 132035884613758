import { useEffect, useState } from "react";

import type { WithStylexArray } from "../../../types";
import { AuthMainFlow } from "./AuthMainFlow";
import type { AuthFlow, AuthProps } from "./AuthTypes";

export type SimplifiedAuthProps = WithStylexArray<{
  onFlowChange: (flow: AuthFlow) => void;
  onAuth: AuthProps["onAuth"];
  allowedMethods?: AuthProps["allowedMethods"];
}>;

export const SimplifiedAuth = ({
  onFlowChange,
  allowedMethods = ["email", "phone", "apple", "google"],
  onAuth,
  styleXArray = [],
}: SimplifiedAuthProps) => {
  const [flow, setFlow] = useState<AuthFlow>("main");

  useEffect(() => {
    if (flow !== "main") {
      onFlowChange(flow);
    }
  }, [flow]);

  return (
    <AuthMainFlow
      flow={flow}
      setFlow={setFlow}
      onAuth={onAuth}
      allowedMethods={allowedMethods}
      styleXArray={styleXArray}
    />
  );
};
