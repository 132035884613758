"use client";

import { useRouter } from "@packages/i18n";
import { LoadingWrapper } from "@packages/ui";
import { useEffect } from "react";

type ClientDeeplinkRedirectProps = {
  to: string;
};

export const ClientDeeplinkRedirect = ({ to }: ClientDeeplinkRedirectProps) => {
  const router = useRouter();

  useEffect(() => {
    if (to) {
      router.replace(to);
    }
  }, [to]);

  return <LoadingWrapper isLoading={true}>{null}</LoadingWrapper>;
};
