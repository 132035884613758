import type { getTranslations } from "@packages/i18n/server";
import { getFullName } from "@packages/sdk";

export const makePADMemberTitle = ({
  base = "",
  user,
  t,
}: {
  base?: string;
  user: any;
  t: Awaited<ReturnType<typeof getTranslations>>;
}) => {
  const memberName = getFullName({
    firstName: user.name,
    lastName: user.last_name,
  });

  return `${base}${memberName?.length ? memberName : t("community_settings_members")}`;
};
