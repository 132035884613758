import type { User } from "@packages/sdk";

export const europeanStyleName = (
  user: Pick<User, "name" | "last_name">,
): string => {
  if (!user) return "";
  const { name, last_name } = user;
  let output = name ?? "";
  if (output.length) output += " ";
  output += last_name ?? "";
  return output;
};
